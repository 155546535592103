import React from "react";
import { Link, graphql } from "gatsby";
import Helmet from "react-helmet";
import HeaderBottom from "../components/HeaderBottom";

import Layout from "../components/layout";
import SEO from "../components/seo";

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const blog = data.blog;
  const page = data.home.frontmatter;
  const blogContent = data.blog.frontmatter;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;
  const commentsLength = blogContent.comments && blogContent.comments.length;

  console.log("data", data.blog, previous, next);

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={blog.frontmatter.title}
        // description={blog.frontmatter.description || post.excerpt}
      />
      <Helmet
        bodyAttributes={{
          class: "scroll-is--active appear-animate",
        }}
      />
      <div class="page-loader dark">
            <div class="loader">Loading...</div>
        </div>

        <a href="#main" class="btn skip-to-content">Skip to Content</a>

        <div class="page bg-dark light-content" id="top">

            <nav class="main-nav dark transparent stick-fixed wow-menubar">
                <div class="full-wrapper relative clearfix">

                    <div class="nav-logo-wrap local-scroll">
                        <a href="/asset/index.html" class="logo">
                        <img src="/asset/images/huxlogo_white.png" alt="Company logo"/>
                        </a>
                    </div>

                    <div class="mobile-nav" role="button" tabindex="0">
                        <i class="fa fa-bars"></i>
                        <span class="sr-only">Menu</span>
                    </div>

                    <a href="/asset/shop-cart-dark.html" class="mobile-cart"><i class="main-nav-icon-cart"></i> (0)</a>

                    <div class="inner-nav desktop-nav">
                        <ul class="clearlist">

                            <li class="d-sm-none d-md-none d-lg-block">
                                <a href="/"><i class="fas fa-home"></i> Home</a>
                            </li>
                            <li class="d-sm-none d-md-none d-lg-block">
                                <a href="/about"><i class="fas fa-dice-d20"></i> About</a>
                            </li>
                            <li class="d-sm-none d-md-none d-lg-block">
                                <a href={page.tokopediaUrl}><i class="main-nav-icon-cart"></i> Shop</a>
                            </li>
                            <li class="d-sm-none d-md-none d-lg-block">
                                <a href="/#tutorial"><i class="fas fa-spa"></i> Tutorial</a>
                            </li>
                            <li class="d-sm-none d-md-none d-lg-block">
                                <a href="/#blog"><i class="fas fa-book-open"></i> Blog</a>
                            </li>

                        </ul>
                    </div>

                </div>
            </nav>

            <main id="main">

                <section class="small-section bg-dark-alfa-50 bg-scroll light-content" data-background="images/full-width-images/section-bg-19.jpg" id="home">
                    <div class="container relative pt-70">

                        <div class="row">

                            <div class="col-md-8">
                                <div class="wow fadeInUpShort" data-wow-delay=".1s">
                                    <h1 class="hs-line-7 mb-40 mb-xs-20">{blogContent.title}</h1>
                                </div>
                                <div class="wow fadeInUpShort" data-wow-delay=".2s">
                                    <div class="mb-20 mb-xs-0">
                                        <div class="blog-item-data">
                                            <a href="#"><i class="fa fa-clock"></i> {blogContent.date}</a>
                                            <span class="separator">&nbsp;</span>
                                            {/* <a href="#"><i class="fa fa-user"></i> John Doe</a>
                                            <span class="separator">&nbsp;</span> */}
                                            <i class="fa fa-folder-open"></i>
                                            <a href="/asset/"> {blogContent.category}</a>
                                            <span class="separator">&nbsp;</span>
                                            <a href="#"><i class="fa fa-comments"></i>{` Comments (${commentsLength})`}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 mt-30 wow fadeInUpShort" data-wow-delay=".1s">
                                <div class="mod-breadcrumbs text-end">
                                    <a href="#">Home</a>&nbsp;<span class="mod-breadcrumbs-slash">•</span>&nbsp;<a href="#">Blog</a>&nbsp;<span class="mod-breadcrumbs-slash">•</span>&nbsp;<span>Single</span>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>


                <section class="page-section bg-dark light-content">
                    <div class="container relative">

                        <div class="row">

                            <div class="col-lg-8 offset-lg-2 mb-sm-80">

                                <div class="blog-item mb-80 mb-xs-40">

        							<div class="blog-item-body">

        							    <div class="lead mt-0">
                                            <p>
                                                {blogContent.intro}
                                            </p>
                                        </div>

                                        <div class="blog-media mt-40 mb-40 mb-xs-30">
                                            <ul class="clearlist content-slider dark-content">
                                                <li>
                                                    <img src={
                                                      blogContent.mainImage
                                                        ? blogContent.mainImage
                                                        : "/assets/img/samples/news-post-img-01.jpg"
                                                    } alt="" />
                                                </li>
                                            </ul>
                                        </div>

                                        <div
          class="post__body"
          dangerouslySetInnerHTML={{ __html: data.blog.html }}
        />

        							</div>

        						</div>

                                <div class="mb-80 mb-xs-40">

                                    <h4 class="blog-page-title">Comments <small class="number">(3)</small></h4>

                                    <ul class="media-list comment-list clearlist">
                                    {blogContent.comments &&
                                      blogContent.comments.map((comment, index) => {
                                        return (
                                          <li class="media comment-item">

                                                                    <a class="float-start" href="#"><img class="media-object comment-avatar" src="/asset/images/user-avatar.png" alt="" width="50" height="50"/></a>

                                                    <div class="media-body">

                                                                          <div class="comment-item-data">
                                                                              <div class="comment-author">
                                                                                  <a href="#">{comment.commenter}</a>
                                                                              </div>
                                                                              {comment.date} <span class="separator">&mdash;</span>
                                                                              <a href="#"><i class="fa fa-comment"></i>&nbsp;Reply</a>
                                                                          </div>

                                                                          <p>
                                                                          {comment.comment}
                                                                          </p>

                                                                          <div class="media comment-item">

                                                        <a class="float-start" href="#"><img class="media-object comment-avatar" src="/asset/images/user-avatar.png" alt=""/></a>

                                                        <div class="media-body">

                                                                                  <div class="comment-item-data">
                                                                                      <div class="comment-author">
                                                                                          <a href="#">{data.site.siteMetadata.companyName}</a>
                                                                                      </div>
                                                                                      {comment.date} <span class="separator">&mdash;</span>
                                                                                      <a href="#"><i class="fa fa-comment"></i>&nbsp;Reply</a>
                                                                                  </div>

                                                          <p>
                                                          {comment.reply}
                                                                                  </p>


                                                        </div>

                                                                          </div>

                                                                      </div>

                                                                  </li>
                                      )})}


                                    </ul>

                                </div>


        						<div class="mb-80 mb-xs-40">

                                    <h4 class="blog-page-title">Leave a comment</h4>

                                    <forma method="post" action="#" id="form" class="form">

                                        <div class="row mb-30 mb-md-20">

                                            <div class="col-md-6 mb-md-20">
                                                <label for="name">Name *</label>
                                                <input type="text" name="name" id="name" class="input-md round form-control" placeholder="Enter your name" maxlength="100" required aria-required="true"/>
                                            </div>

                                            <div class="col-md-6">
                                                <label for="email">Email *</label>
                                                <input type="email" name="email" id="email" class="input-md round form-control" placeholder="Enter your email" maxlength="100" required aria-required="true"/>
                                            </div>

                                        </div>

                                        <div class="mb-30 mb-md-20">
                                            <label for="website">Phone Number</label>
                                            <input type="text" name="website" id="website" class="input-md round form-control" placeholder="Enter your website" maxlength="100"/>
                                        </div>

                                        <div class="mb-30 mb-md-20">
                                            <label for="comment">Comment</label>
                                            <textarea name="comment" id="comment" class="input-md round form-control" rows="6" placeholder="Enter your comment" maxlength="400"></textarea>
                                        </div>

                                        <button type="submit" class="btn btn-mod btn-w btn-medium btn-round" onClick={() => alert("thanks!")}>
                                            Send comment
                                        </button>

                                    </forma>

        						</div>

                                <div class="clearfix mt-40">
                                    {previous &&<a href={previous ? previous.fields.slug : "#"} class="blog-item-more left"><i class="fa fa-chevron-left"></i>&nbsp;Prev post</a>}
                                    {next &&<a href={next ? next.fields.slug : "#"} class="blog-item-more right">Next post&nbsp;<i class="fa fa-chevron-right"></i></a>}
                                </div>

                            </div>

                        </div>

                    </div>
                </section>

            </main>

            <footer class="page-section bg-dark-lighter light-content footer pb-100 pb-sm-50">
                <div class="container">


                    <div class="footer-social-links mb-90 mb-xs-40">
                        <a href={page.instaStoreUrl} title="Instagram" target="_blank"><i class="fab fa-instagram"></i> <span class="sr-only">Instagram Account</span></a>
                    </div>



                    <div class="footer-text">


                        <div class="footer-copy">
                            <a href="/asset/index.html">{`© ${page.companyName} ${new Date().getFullYear()}`}</a>
                        </div>


                        <div class="footer-made">
                            Made with love for great people.
                        </div>

                    </div>


                 </div>


                 <div class="local-scroll">
                     <a href="#top" class="link-to-top"><i class="link-to-top-icon"></i></a>
                 </div>


            </footer>

        </div>

    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        companyName
        tokopediaUrl
        whatsappUrl
      }
    }
    home:markdownRemark(frontmatter: { id: { eq: "home" } }) {
      frontmatter {
        templateKey
        logo
        companyName
        waNum
        waMessage
        email
        instaStoreUrl
        tokopediaUrl
        companyAddress
        messageParagraph {
          messageBody
          messageHeader
        }
        testimonials {
          image
          text
          name
        }
        galleryvideos {
          title
          url
          image
        }
        galleryimages {
          title
          image
        }
        products {
          image
          productUrl
          name
          description
        }
        productGallery {
          label
          desc
          image
        }
        featuresGallery {
          label
          desc
          image
        }
        peopleGallery {
          label
          desc
          image
        }
        tutorial {
          title
          desc
          thumbnail
          video
        }
        tutorialMainImage
        id
        videoUrl
        videoThumbnail
      }
    }
    blog: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        intro
        date(formatString: "MMMM DD, YYYY", locale: "id", fromNow: true)
        category
        mainImage
        comments {
          comment
          commenter
          date(formatString: "MMMM DD, YYYY", locale: "id", fromNow: true)
          reply
        }
      }
    }
  }
`;
